.container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	/* background-color: salmon; */
}

.input {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-direction: column;
	width: 80%;
}

.input textarea {
	width: 80%;
	font-size: large;
}

.input input {
	width: 80%;
}

.list {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	flex-direction: row;
	flex-wrap: wrap;
}

@media (max-width: 768px) {
	.input {
		width: 100%;
	}

	.input textarea {
		width: 100%;
	}
}
