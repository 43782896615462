.conteiner {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}

.main {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	background-color: white;
	align-items: center;
	height: 90%;
	width: 90%;
	border: 1px solid;
	border-radius: 10px;
}

.controls {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 10%;
	width: 96%;
	border-bottom: 1px solid;
}

.calendar {
	display: flex;
	justify-content: space-between;
	margin: 1% 0;
	width: 96%;
	height: 90%;
}

.day {
	text-align: center;
	width: 15%;
	overflow: hidden;
}

.Sabato {
	background-color: gray;
	color: white;
}
.Domenica {
	background-color: brown;
	color: white;
}

.date {
	border-bottom: 0.5px solid #ccc;
	font-weight: bold;
}

.navHeader {
	font-size: 2rem;
	font-weight: bold;
}

.navArrow:hover {
	cursor: pointer;
	font-weight: 900;
}

.hoverTodolist {
	position: fixed;
	left: 25%;
	top: 5%;
	width: 50%;
	height: 90%;
	z-index: 80;
	background-color: #ffffff;
	border: 1px solid black;
	border-radius: 4px;
	overflow: auto;
}

@media (max-width: 768px) {
	.controls {
		border-bottom: none;
	}
	.calendar {
		flex-direction: column;
		overflow: auto;
	}
	.day {
		width: auto;
		overflow: visible;
	}

	.date {
		font-size: 1.2rem;
		border-top: 1.5px solid #333;
	}
	.hoverTodolist {
		left: 5%;
		top: 5%;
		width: 90%;
		height: 90%;
		padding: 2%;
	}
}
