.container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40vh;
	width: 100vw;
}

.formContainer {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	height: 100%;
	width: 30%;
	font-size: 1.2rem;
}

.logout {
	height: 11vh;
	width: 100%;
}

.logoutForm {
	display: flex;
	justify-content: center;
}

@media (max-width: 768px) {
	form {
		width: 100%;
	}

	.container {
		height: 70vh;
	}

	.formContainer {
		width: 80%;
	}
}
