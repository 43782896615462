.container {
	background-color: var(--maincolor);
	/* position: fixed; */
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	width: fit-content;
	height: 100vh;
	font-size: 1.5rem;
	font-weight: bolder;
	padding: 0 1%;
}

.burger {
	position: absolute;
	top: 0.5rem;
	right: 1rem;
	display: none;
	flex-direction: column;
	justify-content: space-between;
	width: 30px;
	height: 21px;
}

.burger_bar {
	height: 4px;
	width: 100%;
	/* background-color: white; */
	background-color: var(--maincolor);
	border-radius: 10px;
}

.list {
	width: 100%;
	display: flex;
	justify-content: center;
	padding: 0.5rem;
}
.list:hover {
	text-decoration: underline;
}

.a {
	color: white;
	text-decoration: none;
	/* width: 100%; */
	/* display: flex; */
	/* justify-content: center; */
}

.active {
	background-color: var(--contrast);
	color: var(--maincolor);
	border-radius: 10px;
	text-decoration: underline;
}

@media (max-width: 768px) {
	.container {
		min-width: 100%;
		/* height: fit-content; */
		display: none;
	}

	.show {
		display: flex;
	}

	.burger {
		display: flex;
	}

	.hide {
		display: none;
	}

	.list {
		width: 100%;
	}
}
