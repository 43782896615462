.card {
	width: 200px;
	height: 300px;
	margin: 3% 2.5%;
	color: #000;
	background-color: #fff;
	border: var(--maincolor) solid 1px;
	box-shadow: 2px 2px 2px #333;
	border-radius: 3px;
	display: flex;
	justify-content: space-around;
	cursor: pointer;
}

.card:hover {
	box-shadow: 6px 6px 2px var(--maincolor);
}

.first {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	text-align: center;
	width: 95%;
}
.list {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	text-align: center;
	width: 95%;
	padding: 10px 0;
}

.description {
	overflow: auto;
	/* height: 40%; */
}

.date {
	color: var(--maincolor);
	font-weight: 600;
	font-size: 1.1rem;
}

.workType {
	padding: 1% 5%;
	margin-bottom: 10px;
	border-radius: 100px;
	border: #000 1px solid;
}

.cheklist {
	width: fit-content;
	float: right;
}
.logManual {
	width: fit-content;
	float: left;
}

.askBackground {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: grey;
	display: flex;
	align-items: center;
	justify-content: center;
}

/*
* ---------------------------
* Type: CLENDAR
* ---------------------------
*/

.task {
	font-size: 14px;
	line-height: normal;
	border: 1px solid;
	margin: 2% 3%;
	padding: 1%;
	border-radius: 4px;
}

.calendar_pTitle {
	font-weight: bold;
}

.TODO {
	background-color: var(--TODO);
}

.ONGOING {
	background-color: var(--ONGOING);
}

.COMPLETED {
	background-color: var(--COMPLETED);
}

.PAUSED {
	background-color: var(--PAUSED);
}

.ABORTED {
	background-color: var(--ABORTED);
}

.hide {
	display: none;
}
