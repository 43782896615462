.container {
	width: 96%;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;
	height: 79%;
}

.header {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	flex-direction: row;
	width: 100%;
	font-size: 2rem;
	height: 10%;
	text-align: center;
}

.header__content {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 2.5rem;
	cursor: pointer;
}

.header__content:hover {
	font-weight: bold;
}

.cards{
	width: 100%;
	height: 90%;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: center;
	overflow: auto;
}

/* .card {
	width: 200px;
	height: 300px;
	margin: 3% 2.5%;
	color: #000;
	background-color: #fff;
	border: var(--maincolor) solid 1px;
	box-shadow: 2px 2px 2px #333;
	border-radius: 3px;
	display: flex;
	justify-content: space-around;
	cursor: pointer;
} */

/* .card:hover {
	box-shadow: 6px 6px 2px var(--maincolor);
	background-color: var(--activeLinkLight);
} */

.list {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	text-align: center;
	width: 95%;
}

.description {
	overflow: auto;
}

.workType {
	padding: 1% 5%;
	border-radius: 100px;
	border: #000 1px solid;
}

/* .ONGOING {
	background-color: var(--ONGOING);
}

.COMPLETED {
	background-color: var(--COMPLETED);
}

.PAUSED {
	background-color: var(--PAUSED);
}

.ABORTED {
	background-color: var(--ABORTED);
}
.TODO {
	background-color: var(--TODO);
} */

@media(max-width: 768px){

}