.container {
	position: fixed;
	left: 10%;
	top: 5%;
	width: 80%;
	height: 90%;
	z-index: 51;
	background-color: #ffffff;
	border: 1px solid black;
	border-radius: 4px;
	overflow: auto;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.header {
	display: flex;
	width: 90%;
	justify-content: space-around;
	border-bottom: var(--maincolor) solid 1px;
}

.header__title {
	align-items: center;
}

.headerH1 {
	margin-bottom: 0.5rem;
}

.description {
	display: flex;
	align-items: center;
}

.description__icons {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
}
/*-------------------------
Colori status progetto
-------------------------*/

.todolist {
	width: 90%;
	border-bottom: var(--maincolor) solid 1px;
	padding-bottom: 1rem;
}

.logs {
	width: 100%;
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	/* align-items: flex-start; */
}

/*-------------------------
Colori status progetto
-------------------------*/

.TODO {
	background-color: var(--TODO);
	width: min-content;
	margin-bottom: 1%;
}

.ONGOING {
	background-color: var(--ONGOING);
	width: min-content;
	margin-bottom: 1%;
}

.COMPLETED {
	background-color: var(--COMPLETED);
	width: min-content;
	margin-bottom: 1%;
}

.SUSPENDED {
	background-color: var(--SUSPENDED);
	width: min-content;
	margin-bottom: 1%;
}

.ABORTED {
	background-color: var(--ABORTED);
	width: min-content;
	margin-bottom: 1%;
}

@media (max-width: 768px) {
	.header {
		flex-direction: column;
	}

	.description__icons {
		width: 100%;
		flex-direction: row;
	}
}
