.controls {
	width: 100%;
	display: flex;
	justify-content: space-around;
}

.icons {
	font-size: 2rem;
	cursor: pointer;
}

.icons:hover{
	text-shadow: 0px 0px 5px black;
}

.play:hover {
	color: var(--ONGOING);
}
.pause:hover {
	color: var(--PAUSED);
}
.stop:hover {
	color: var(--ABORTED);
}

.edit:hover {
	color: var(--TODO);
}