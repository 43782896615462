.wrapper {
	position: absolute;
	top: 20%;
	left: 20%;
	background-color: var(--contrast);
	z-index: 20;
	height: 60%;
	width: 60%;
	padding: 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}

.form {
	overflow: auto;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	padding: 2rem 0.5rem;
}

.labelWrap {
	display: flex;
	justify-content: space-between;
	gap: 1rem;
}
.input {
	text-align: center;
	padding: 0.2rem;
}

.checkbox {
	width: 30%;
}

.number {
	width: 30%;
}

.buttons {
	display: flex;
	gap: 2rem;
	font-size: 2rem;
}
