.wrapper {
	width: 96%;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;
	gap: 1rem;
	height: 79%;
	overflow: auto;
}

.header {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 2rem;
	padding: 1rem 0;
	width: 100%;
	color: var(--ONGOING);
}

.invocesWrap {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	width: 100%;
	gap: 1rem;
}
