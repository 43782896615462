.base {
	padding: 0.5rem 1.5rem;
	margin: 0.5rem 0.5rem;
	border-radius: 10px;
	outline: none;
	cursor: pointer;
	text-decoration: none;
	display: inline-block;
	box-shadow: 1px 1px 4px grey;
}

.default {
	background-color: var(--ButtonStdBgColor);
	color: var(--ButtonStdTxtColor);
	border: var(--ButtonStdTxtColor) 1px solid;
}
.default:hover,
.default:active {
	background-color: var(--ButtonStdTxtColor);
	color: var(--ButtonStdBgColor);
	border: var(--ButtonStdBgColor) 1px solid;
}

.reverse {
	background-color: var(--ButtonStdTxtColor);
	color: var(--ButtonStdBgColor);
	border: var(--ButtonStdBgColor) 1px solid;
}
.reverse:hover,
.reverse:active {
	background-color: var(--ButtonStdBgColor);
	color: var(--ButtonStdTxtColor);
	border: var(--ButtonStdTxtColor) 1px solid;
}

.danger {
	background-color: var(--ButtonDngrBgColor);
	color: var(--ButtonDngrTxtColor);
	border: var(--ButtonDngrTxtColor) 1px solid;
	font-weight: bold;
}

.danger:hover,
.danger:active {
	background-color: var(--ButtonDngrTxtColor);
	color: var(--ButtonDngrBgColor);
	border: var(--ButtonDngrBgColor) 1px solid;
}

.base:disabled,
.base:hover:disabled,
.base:active:disabled {
	background-color: lightgrey;
	color: lightslategray;
	border: lightslategray 1px solid;
}

.small {
	font-size: 0.6rem;
}
.big {
	font-size: 1.5rem;
}

._50 {
	max-width: 50%;
}
