:root {
	/* App commons */
	--maincolor: #282c34;
	--contrast: white;
	--text: white;
	--activeLink: #61dafb;
	--activeLinkLight: hsl(193, 100%, 95%);
	--backdrop: rgba(40, 44, 52, 0.75);
	--overlay: #3c5280;
	--overlayBg: #3c528091;
	/* --overlayBg: hsl(223, 100%, 93%); */
	/* --overlayBg: rgba(255, 255, 255, 0.9); */

	/* Elemetns */
	--StandardBgColor: #61dafb;
	--DangerBgColor: #d11010;

	/* Button */
	--ButtonStdBgColor: #61dafb;
	--ButtonStdTxtColor: #282c34;
	--ButtonDngrBgColor: #d11010;
	/* --ButtonDngrBgColor: crimson; */
	--ButtonDngrTxtColor: white;

	--ONGOING: hsl(100, 50%, 50%);
	/* --COMPLETED: hsl(0, 50%, 50%); */
	--COMPLETED: hsl(0, 0%, 80%);
	--COMPLETEDtext: hsl(0, 0%, 60%);
	--PAUSED: hsl(51, 50%, 50%);
	/* --ABORTED: hsl(0, 0%, 48%); */
	--ABORTED: hsl(0, 50%, 50%);
	--TODO: hsl(180, 50%, 50%);
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

/* *::after,
::before {
	box-sizing: border-box;
} */

html {
	max-height: 100vh;
	overflow: hidden;
	line-height: 1.5rem;
	font-size: 18px;
}

.App {
	display: flex;
	height: 100vh;
	width: 100vw;
}

.Content {
	display: flex;
	width: 100vw;
	height: 100vh;
	background-color: #eee;
	overflow: auto;
}

.ONGOING {
	background-color: var(--ONGOING);
}

.COMPLETED {
	background-color: var(--COMPLETED);
}

.PAUSED {
	background-color: var(--PAUSED);
}

.ABORTED {
	background-color: var(--ABORTED);
}
.TODO {
	background-color: var(--TODO);
}