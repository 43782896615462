.container {
	width: 96%;
	overflow: auto;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-wrap: wrap;
	height: 79%;
	border-top: 1px solid lightgrey;
	padding-top: 1%;
	padding-bottom: 10%;
}

.filters {
	width: 96%;
	height: fit-content;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1.5% 0;
}

.filters__inputs {
	display: flex;
	display: flex;
	align-items: center;
	justify-content: center;
	height: fit-content;
	width: 100%;
}

.filtersHandler {
	display: none;
}

.filterButton {
	background-color: var(--activeLink);
	padding: 1%;
	width: 100%;
}

.dataRow {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	width: 80%;
	border-bottom: 1px solid lightgrey;
	height: fit-content;
}

/* .dataRow p {
	border: 1px solid red;
	width: 10%;
	min-width: 150px;
} */

.logDate {
	width: 10%;
}

.dataCell {
	width: 10%;
	min-width: 180px;
}

.typeDate {
	min-width: fit-content;
	max-width: fit-content;
	padding: 0 1%;
}
.typeDescription {
	word-wrap: break-word;
	white-space: pre-line;
	width: 50%;
	padding-bottom: 1.5%;
}

.typeStatus {
	font-size: small;
	text-decoration: underline;
	max-width: fit-content;
	min-width: fit-content;
	border: 1px solid gray;
	border-radius: 3px;
	padding: 0 10px;
	margin-bottom: 10px;
	box-shadow: 1px 1px 2px black;
}

.typeWorkDesc {
	font-weight: bold;
	font-size: 1.2rem;
	padding: 1% 0;
	width: 100%;
}

.Invalid {
	color: #0000ff00;
}

.active {
	color: Brown;
	border: 1px solid red;
	box-shadow: 1px 1px 2px brown;
}

.inactive {
	color: grey;
}

@media (max-width: 768px) {
	.container {
		padding-bottom: 15%;
	}

	.filters {
		flex-direction: column;
		padding: 1.5% 0;
	}
	.filters__inputs {
		flex-direction: column;
	}

	.filtersHandler {
		display: block;
		width: 100%;
		text-align: right;
		font-weight: bold;
		text-decoration: underline;
		padding: 1% 0;
	}

	.inputs__hidden {
		display: none;
	}

	.dataRow {
		width: 100%;
		flex-direction: column;
	}

	.dataCell {
		width: 100%;
	}

	.typeProject,
	.typeDate {
		text-align: center;
	}

	.typeSummary {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		padding-right: 5%;
	}
}

@keyframes openFiltersPannel {
	0% {
		height: 20%;
	}
	100% {
		height: 100%;
	}
}

.fadeIn {
	animation: openFiltersPannel 5000ms alternate;
}
