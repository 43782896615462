.contactsCointainer {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	overflow-y: auto;
	padding-bottom: 60px;
}

.card {
	width: 200px;
	height: 300px;
	margin: 3% 2.5%;
	color: #000;
	background-color: #fff;
	border: var(--maincolor) solid 1px;
	box-shadow: 2px 2px 2px #333;
	border-radius: 3px;
	display: flex;
	justify-content: space-around;
	cursor: pointer;
}

.card:hover {
	box-shadow: 6px 6px 2px var(--maincolor);
	/* border: var(--maincolor) solid 2px; */
	background-color: var(--activeLinkLight);
}

.list {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	text-align: center;
}

@media(max-width: 768px){
	.contactsCointainer{
		padding-top: 30px;
	}

	.card{
		width: 80%;
		height: 100px;
	}
}