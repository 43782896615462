@font-face {
	font-family: 'Material Icons';
	font-style: normal;
	font-weight: 400;
	src: url('./../assets/MaterialSymbolsOutlined.ttf'); /* For IE6-8 */
	src: local('Material Icons'), local('MaterialIcons-Regular'),
		url('./../assets/MaterialSymbolsOutlined.ttf') format('woff2'),
		url('./../assets/MaterialSymbolsOutlined.ttf') format('woff'),
		url('./../assets/MaterialSymbolsOutlined.ttf') format('truetype');
}

.material-iconz {
	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	/* font-size: 24px; Preferred icon size */
	display: inline-block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;

	/* Support for all WebKit browsers. */
	-webkit-font-smoothing: antialiased;
	/* Support for Safari and Chrome. */
	text-rendering: optimizeLegibility;

	/* Support for Firefox. */
	-moz-osx-font-smoothing: grayscale;

	/* Support for IE. */
	font-feature-settings: 'liga';

	padding: 0.2rem;
	border: 1px solid transparent;
	border-radius: 50%;
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */

	font-size: inherit;
	color: inherit;
}

.material-icons:hover {
	cursor: pointer;
}

.everTodo,
.todo:hover {
	color: var(--TODO);
	text-shadow: 1px 2px 3px var(--DISABLED);
	/* background-color: var(--higlight); */
}
.everStopped,
.stopped:hover {
	color: var(--ERROR);
	text-shadow: 1px 2px 3px var(--DISABLED);
	/* background-color: var(--higlight); */
}

.everPaused,
.paused:hover {
	color: var(--PAUSED);
	text-shadow: 1px 2px 3px var(--DISABLED);
	/* background-color: var(--higlight); */
}

.everOngoing,
.ongoing:hover {
	color: var(--ONGOING);
	text-shadow: 1px 2px 3px var(--DISABLED);
	/* background-color: var(--higlight); */
}

.everTodo:hover {
	text-shadow: 1px 2px 4px var(--TODO);
}
.everOngoing:hover {
	text-shadow: 1px 2px 4px var(--ONGOING);
}
.everPaused:hover {
	text-shadow: 1px 2px 4px var(--PAUSED);
}
.everStopped:hover {
	text-shadow: 1px 2px 4px var(--ERROR);
}
