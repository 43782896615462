.container {
	width: 31%;
	margin: 1%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	border-radius: 20px;
}

.priority3 {
	border: 5px solid var(--TODO);
}
.priority2 {
	border: 5px solid var(--PAUSED);
}
.priority1 {
	border: 5px solid var(--ABORTED);
}

.priority9 {
	border: 5px solid var(--ONGOING);
}

.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	word-break: break-all;
	max-height: 220px;
	overflow: auto;
}

.text {
	font-size: large;
	padding: 8px;
	overflow: auto;
}
.controllers {
	width: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
}
.del {
	color: var(--contrast);
	height: fit-content;
	width: fit-content;
	cursor: pointer;
	padding: 2% 5%;
	margin: 5% 0;
	background-color: var(--ABORTED);
	border: 1px solid var(--maincolor);
	border-radius: 12px;
}
.done {
	height: fit-content;
	width: fit-content;
	cursor: pointer;
	padding: 2% 5%;
	margin: 5% 0;
	background-color: var(--ONGOING);
	border: 1px solid var(--maincolor);
	border-radius: 12px;
}

@media (max-width: 768px) {
	.container {
		width: 100%;
	}
}
