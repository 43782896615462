.container {
	overflow-y: auto;
	overflow-x: hidden;
	height: 92%;
	margin-top: 2%;
}

.task {
	font-size: 14px;
	line-height: normal;
	border: 1px solid;
	margin-right: 3%;
	margin-bottom: 2%;
	padding: 1%;
	border-radius: 4px;
}

.iconAdd {
	cursor: pointer;
	font-size: 1.5rem;
	margin-top: 0.5rem;
}
.iconAdd:hover {
	text-shadow: 0px 0px 2px black;
	color: var(--ONGOING);
}

.ONGOING {
	background-color: var(--ONGOING);
}

.COMPLETED {
	background-color: var(--COMPLETED);
	color: VAR(--COMPLETEDtext);
}

.PAUSED {
	background-color: var(--PAUSED);
}

.ABORTED {
	background-color: var(--ABORTED);
}
.TODO {
	background-color: var(--TODO);
}

@media (max-width: 768px) {
	.container {
		overflow-y: unset;
		overflow-x: unset;
	}
}
