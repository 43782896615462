.card {
	width: 200px;
	height: 300px;
	margin: 3% 2.5%;
	color: #000;
	background-color: #fff;
	border: var(--maincolor) solid 1px;
	box-shadow: 2px 2px 2px #333;
	border-radius: 3px;
	display: flex;
	justify-content: space-around;
	cursor: pointer;
}
.c_project {
	width: 400px;
}

.card:hover {
	box-shadow: 6px 6px 2px var(--maincolor);
	background-color: var(--activeLinkLight);
}

.list {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	text-align: center;
	width: 95%;
}

.description {
	overflow: auto;
}

.workType {
	padding: 1% 5%;
	border-radius: 100px;
	border: #000 1px solid;
}

.ONGOING {
	background-color: var(--ONGOING);
}

.COMPLETED {
	background-color: var(--COMPLETED);
}

.PAUSED {
	background-color: var(--PAUSED);
}

.ABORTED {
	background-color: var(--ABORTED);
}
.TODO {
	background-color: var(--TODO);
}

@media (max-width: 768px) {
	.card {
		width: 100%;
		height: 180px;
	}
}
